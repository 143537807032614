import type { FeatureCollection } from 'geojson';
import type { FeatureLike } from 'ol/Feature';
import type { Point, SimpleGeometry } from 'ol/geom';
import type { SelectEvent } from 'ol/interaction/Select';
import type { StyleFunction } from 'ol/style/Style';

import { Outlet, useNavigate } from '@remix-run/react';
import { useCallback, useEffect, useState } from 'react';

import { Portal } from '@mantine/core';
import { IconShape, IconShapeOff } from '@tabler/icons-react';
import { Feature, MapBrowserEvent } from 'ol';
import { useTranslation } from 'react-i18next';

import { ExcludedAreasTooltip } from '@/components/ExcludedAreasTooltip';
import {
  GeoJSONForklifts,
  GeoJSONStyled,
  LayerVisibilityBtn,
  MapFiltersContainer,
  getExcludedAreasSelectedStyles,
  getVehicleSelectedStyles,
  useExcludedAreasLayer,
  useMap,
  useSelect,
  useVehiclesLayer,
  type LayerVisibilityBtnProps,
} from '@/lib/OpenLayers';
import { LiveRouteProvider } from '@/routes/providers/LiveRouteProvider';

import { useFetcherPositions } from './useFetcherPositions';

const forkliftsGeoJsonFormat = new GeoJSONForklifts({
  dataProjection: undefined,
  featureClass: Feature,
});

const areasGeoJsonFormat = new GeoJSONStyled({
  dataProjection: undefined,
  featureClass: Feature,
});

const getFeaturesStyle: StyleFunction = (feature, resolution) => {
  if (feature.get('serial')) return getVehicleSelectedStyles(feature, resolution);
  return getExcludedAreasSelectedStyles(feature, resolution);
};

interface MapLiveProps {
  forklifts: FeatureCollection;
  excludedAreas: FeatureCollection;
}

export const MapLive = (props: MapLiveProps): JSX.Element => {
  const { excludedAreas, forklifts } = props;

  const navigate = useNavigate();
  const navigateToFeature = useCallback(
    (e: SelectEvent) => {
      if (e.selected.length === 0 && e.deselected.length > 0) {
        navigate('/live/vehicles');
      } else if (e.selected.length > 0) {
        const id = e.selected[0]?.getId();
        if (!id) return;
        navigate(`/live/vehicles/${id.toString()}`);
      }
    },
    [navigate],
  );

  const { map, mapTargetId } = useMap();

  const { source: forkliftsSource, layer: forkliftsLayer } = useVehiclesLayer({
    sourceOpts: {
      features: forkliftsGeoJsonFormat.readFeatures(forklifts) as Feature<Point>[],
    },
    layerOpts: {
      zIndex: 10,
    },
  });
  const { source: excludedAreasSource, layer: excludedAreasLayer } = useExcludedAreasLayer({
    sourceOpts: {
      features: areasGeoJsonFormat.readFeatures(excludedAreas) as Feature<SimpleGeometry>[],
    },
    layerOpts: {
      zIndex: 9,
    },
  });

  const { select: selectInteraction } = useSelect({
    layers: [forkliftsLayer],
    style: getFeaturesStyle,
    onSelect: navigateToFeature,
  });

  useEffect(() => {
    map.addLayer(forkliftsLayer);
    map.addLayer(excludedAreasLayer);
    map.addInteraction(selectInteraction);
    return () => {
      map.removeLayer(forkliftsLayer);
      map.removeLayer(excludedAreasLayer);
      map.removeInteraction(selectInteraction);
    };
  }, [map, forkliftsLayer, excludedAreasLayer, selectInteraction]);

  useFetcherPositions({ forkliftsSource });

  const [hoveredAreas, setHoveredAreas] = useState<{ pixel: number[]; features: FeatureLike[] } | null>(null);
  useEffect(() => {
    const hideTooltip = () => setHoveredAreas(null);

    const target = document.getElementById(mapTargetId);
    target?.addEventListener('pointerleave', hideTooltip);

    const displayAreaInfo = ({ pixel, dragging }: MapBrowserEvent<UIEvent>) => {
      if (dragging) {
        hideTooltip();
        return;
      }

      const featuresArray = map.getFeaturesAtPixel(pixel, {
        layerFilter: (layer) => layer === excludedAreasLayer,
      });

      if (featuresArray.length > 0) {
        setHoveredAreas({ pixel, features: featuresArray });
      } else {
        hideTooltip();
      }
    };

    map.on('pointermove', displayAreaInfo);
    return () => {
      target?.removeEventListener('pointerleave', hideTooltip);
      map.un('pointermove', displayAreaInfo);
    };
  }, [map, mapTargetId, excludedAreasLayer]);

  return (
    <LiveRouteProvider value={{ excludedAreasLayer, excludedAreasSource, forkliftsLayer, forkliftsSource, selectInteraction }}>
      <Portal target='#map-controls-top-left'>
        <LayerControls excludedAreasLayer={excludedAreasLayer} />
      </Portal>
      <Outlet />
      <ExcludedAreasTooltip target={map.getTargetElement()} data={hoveredAreas} />
    </LiveRouteProvider>
  );
};

const LayerControls = ({ excludedAreasLayer }: { excludedAreasLayer: LayerVisibilityBtnProps['layer'] }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <MapFiltersContainer>
      <LayerVisibilityBtn
        layer={excludedAreasLayer}
        label={t('liveRoute.excludedAreasLayer')}
        iconOn={<IconShape />}
        iconOff={<IconShapeOff />}
      />
    </MapFiltersContainer>
  );
};
