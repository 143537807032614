import type { loader as posLoader } from '@/routes/forklifts-positions';
import type Feature from 'ol/Feature';
import type { SimpleGeometry } from 'ol/geom';
import type VectorSource from 'ol/source/Vector';

import { useFetcher } from '@remix-run/react';
import { useEffect } from 'react';

import { useInterval } from '@/hooks';
import { VEHICLE_ANGLE_KEY, VEHICLE_VARIANT_KEY, getForkliftVariantForState } from '@/lib/OpenLayers';

export const createQueryFromIdsArray = (ids: number[]) => `?id=${ids.join('&id=')}`;

export const useFetcherPositions = ({
  query,
  forkliftsSource,
}: {
  forkliftsSource: VectorSource<Feature<SimpleGeometry>>;
  query?: string;
}) => {
  const { data, load, state } = useFetcher<typeof posLoader>({ key: query ?? 'forklift-positions' });

  const fn = () => {
    if (document.visibilityState !== 'visible' || state !== 'idle') {
      return;
    }
    if (query) {
      load(`/forklifts-positions${query}`);
    } else {
      load('/forklifts-positions');
    }
  };

  useInterval(fn, Number.parseInt(globalThis.ENV.POSITIONS_INTERVAL_TIME, 10));

  useEffect(() => {
    const newPos = data;
    if (newPos) {
      forkliftsSource.forEachFeature((feature) => {
        const id = feature.getId()?.toString();
        if (id) {
          const newData = newPos[id];
          if (newData) {
            feature.getGeometry()?.setCoordinates(newData.coordinates);
            feature.set(VEHICLE_ANGLE_KEY, newData.angle);
            feature.set(VEHICLE_VARIANT_KEY, getForkliftVariantForState(newData.state));
          }
        }
      });
    }
  }, [data, forkliftsSource]);
};
