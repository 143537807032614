import { json } from '@remix-run/node';
import type { ShouldRevalidateFunction } from '@remix-run/react';
import { useLoaderData, useLocation, useNavigate } from '@remix-run/react';

import { ScrollArea, Stack, Tabs } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { AREA_STATES } from '@/api/enums';
import { getExcludedAreasList, getForkliftsGeoJson } from '@/api/map.server';
import { ClientOnly } from '@/components/ClientOnly';
import { GeneralErrorBoundary } from '@/components/ErrorBoundary';

import { MapLive } from './MapLive.client';

export const loader = async () => {
  const [forklifts, excludedAreas] = await Promise.all([
    getForkliftsGeoJson(),
    getExcludedAreasList({ state: [AREA_STATES.ACTIVE] }),
  ]);

  return json({
    excludedAreas,
    forklifts,
  });
};

export const shouldRevalidate: ShouldRevalidateFunction = () => false;

export default function RouteComponent() {
  const { excludedAreas, forklifts } = useLoaderData<typeof loader>();
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const { t } = useTranslation();

  return (
    <Stack h='100vh' gap={0}>
      <Tabs
        pt='sm'
        value={routerLocation.pathname.split('/')[2]}
        onChange={(value) => navigate(`./${value}`)}
        keepMounted={false}
      >
        <Tabs.List aria-label={t('navbar.live')} grow>
          <Tabs.Tab value='vehicles'>{t('navbar.vehicles')}</Tabs.Tab>
          <Tabs.Tab value='notifications'>{t('navbar.notifications')}</Tabs.Tab>
          {/* <Tabs.Tab value='excluded-areas'>{t('navbar.excluded-areas')}</Tabs.Tab> */}
        </Tabs.List>
      </Tabs>

      <ScrollArea scrollbars='y'>
        <ClientOnly>{() => <MapLive excludedAreas={excludedAreas} forklifts={forklifts} />}</ClientOnly>
      </ScrollArea>
    </Stack>
  );
}

export const ErrorBoundary = GeneralErrorBoundary;
